
//公共库
import { MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerSetList, saveBloggerSet, setBloggerPrivacy, setBloggerCompanion } from "@/api/request/blogger";

//组件
@Component({
  name: "bloggerSet",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = false; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    blogger_id_str: "",

    //临时数据
    country: "",
    nick_name: "",
    blogger_id: 0,
    is_privacy: -1,
    is_companion: -1,
  };

  //私密列表
  private privacyList: any[] = [
    { privacy: -1, name: "全部博主" },
    { privacy: 0, name: "非私密博主" },
    { privacy: 1, name: "私密博主" },
  ];

  //伴侣博主
  private companionList: any[] = [
    { companion: -1, name: "全部伴侣博主" },
    { companion: 1, name: "伴侣博主" },
    { companion: 0, name: "非伴侣博主" },
  ];

  //创建调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerSetList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理私密博主
  private handlePrivacy(row: any): void {
    //显示提示
    MessageBox.confirm("设为私密博主操作不可恢复，确定设置吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await setBloggerPrivacy({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //处理编辑
  private async handleEdit(row: any, show: number) {
    //显示等待
    this.listLoading = true;

    //数据请求
    await saveBloggerSet({ blogger_id: row.id, show_guardian: show });

    //获取列表
    this.getList();
  }

  //处理伴侣
  private async handleCompanion(row: any, status: number) {
    //显示等待
    this.listLoading = true;

    //数据请求
    await setBloggerCompanion({ id: row.id, status: status });

    //获取列表
    this.getList();
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }
}
